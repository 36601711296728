:root {
  --font-family: 'Roboto', sans-serif; /* Default, will be overridden */
}

body {
  font-family: var(--font-family);
}

.icon {
  margin-left: 1rem;
  /* Add styles for icons */
}

main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clipped-image {
  clip-path: polygon(48.5% 0%, 89.7% 5.5%, 100% 40.8%, 98.1% 83.5%, 70.2% 100%, 35.8% 90.8%, 5.2% 74.3%, 0% 43%, 12.7% 8.5%);
}
